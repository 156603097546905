<template>
	<div :id="`block-${$vnode.key}`" class="c-block-list-rte">
		<LongReadTarget
			v-if="title"
			class="relative mb-2xs/v"
			:container-id="`block-${$vnode.key}`"
			:link-to-toc="{
				name: `Spring til &quot;${title}&quot; i indholdsfortegnelsen`,
				attrs: {
					'data-focus-ignore': true,
				},
			}"
			:title="title"
			:disabled="!showTitleInToc"
		>
			<BaseH2 v-text="title" />
		</LongReadTarget>
		<div v-if="text" class="s-rich-text" v-html="text"></div>
	</div>
</template>

<script>
import { LongReadTarget } from '~/components/shared/LongRead';
export default {
	name: 'BlockListRte',
	components: { LongReadTarget },
	inheritAttrs: false,

	props: {
		showTitleInToc: Boolean,
		text: String,
		title: String,
	},
};
</script>
